import React from "react";
import fbIcon from "./../assets/icons/facebook_icon.svg";
import instaIcon from "./../assets/icons/instagram_icon.svg";
import locationIcon from "./../assets/icons/locatin_icon.svg";
import phoneIcon from "./../assets/icons/phone_icon.svg";
import webIcon from "./../assets/icons/web_icon.svg";
import emailIcon from "./../assets/icons/email_icon.svg";
const Footer = () => {
  return (
    <footer
      className="bg-[#320a0a] items-start"
      style={{ paddingTop: "3rem", paddingBottom: "6rem" }}
    >
      <div className="mx-auto px-8 py-0">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-orange-100 items-start">
          {/* Contact Us Section */}
          <div className="col-span-1">
            <h4 className="text-lg">Contact Us</h4>
            <h2 className="text-2xl font-bold mb-4">Kontaktet</h2>
          </div>

          {/* Social Media Section */}
          <div className="col-span-1">
            <div className="mb-4">
              <a
                href="https://www.facebook.com/profile.php?id=61566412186559&mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={fbIcon}
                  alt="Facebook Icon"
                  className="inline-block h-8 w-8 mr-2"
                />
                <span>Këndi Studio</span>
              </a>
            </div>
            <div className="mb-4">
              <a
                href="https://www.instagram.com/kendistudio_?igsh=MWFqN3lwemtmZGwyMw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instaIcon}
                  alt="Instagram Icon"
                  className="inline-block h-8 w-8 mr-2"
                />
                <span>studiokendi_</span>
              </a>
            </div>
          </div>

          {/* Email and Phone Section */}
          <div className="col-span-1">
            <div className="mb-4">
              <a href="mailto:info@kendi.com">
                <img
                  src={emailIcon}
                  alt="Email Icon"
                  className="inline-block h-8 w-8 mr-2"
                />
                <span>info@kendi.com</span>
              </a>
            </div>
            <div className="mb-4 flex flex-row">
              <img
                src={phoneIcon}
                alt="Phone Icon"
                className="inline-block h-8 w-8 mr-2"
              />
              <div className="flex flex-col">
                <span>+383 45 60 49 92</span>
                <span>+383 45 99 91 55</span>
              </div>
            </div>
          </div>

          {/* Website and Address Section */}
          <div className="col-span-1">
            <div className="mb-4">
              <a
                href="https://www.kendistudio.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={webIcon}
                  alt="Web Icon"
                  className="inline-block h-8 w-8 mr-2"
                />
                <span>www.kendistudio.com</span>
              </a>
            </div>
            <div className="mb-4">
              <a
                href="https://www.google.com/maps/search/?api=1&query=Rr.+Rexhep+Bislimi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={locationIcon}
                  alt="Location Icon"
                  className="inline-block h-8 w-8 mr-2"
                />
                <span>Rr. "Rexhep Bislimi"</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
