import React from "react";
import fbIcon from "./../assets/icons/facebook_icon.svg";
import instaIcon from "./../assets/icons/instagram_icon.svg";
import locationIcon from "./../assets/icons/locatin_icon.svg";
import phoneIcon from "./../assets/icons/phone_icon.svg";
import webIcon from "./../assets/icons/web_icon.svg";
import emailIcon from "./../assets/icons/email_icon.svg";

const Contact = () => {
  const cards = [
    { icon: fbIcon, text: "Këndi Studio", link:'https://www.facebook.com/profile.php?id=61566412186559&mibextid=LQQJ4d', alt: "Facebook Icon" },
    { icon: instaIcon, text: "studiokendi_",link:'https://www.instagram.com/kendistudio_?igsh=MWFqN3lwemtmZGwyMw==', alt: "Instagram Icon" },
    { icon: emailIcon, text: "info@kendi.com",link:'mailto:info@kendi.com', alt: "Email Icon" },
    // { icon: phoneIcon, text: "+383 45 99 91 55", alt: "Phone Icon" },
    { 
        icon: phoneIcon, 
        text: (
          <>
            +383 45 60 49 92
            <br />
            +383 45 99 91 55
          </>
        ), 
        alt: "Phone Icon" 
      }, // Combined phone numbers in one card
    { icon: webIcon, text: "www.kendistudio.com", link:'https://www.kendistudio.com',alt: "Web Icon" },
    { icon: locationIcon, text: 'Rr. "Rexhep Bislimi"', link:'https://www.google.com/maps/search/?api=1&query=Rr.+Rexhep+Bislimi', alt: "Location Icon" },
  ];

  return (
    <section
      className="bg-[#581010] text-orange-100 p-10 flex flex-col justify-center items-center pt-20 pb-24"
      style={{
        minHeight: "100vh",
      }}
    >
      <h2 className="text-4xl font-bold mb-12 text-orange-100">Contact us</h2>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className="p-4 bg-[#782525] shadow-lg min-h-[220px] transform transition-all duration-300 ease-in-out relative flex flex-col justify-center items-center hover:shadow-light hover:scale-105 cursor-pointer"
            style={{ animation: `fadeIn ${1 + index * 0.2}s forwards` }}
          >
            <div className="border-decor top-left"></div>
            <div className="border-decor bottom-right"></div>
            {/* <a
              href={card.link || "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center"
            >
            <img
              src={card.icon}
              alt={card.alt}
              className="h-10 w-10 mb-3 transition-transform duration-300 hover:scale-110"
            />
            <span className="text-center">{card.text}</span>
            </a>
          </div>
        ))} */}
        {card.link ? (
      <a
        href={card.link}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center"
      >
        <img
          src={card.icon}
          alt={card.alt}
          className="h-10 w-10 mb-3 transition-transform duration-300 hover:scale-110"
        />
        <span className="text-center">{card.text}</span>
      </a>
    ) : (
      <div className="flex flex-col items-center">
        <img
          src={card.icon}
          alt={card.alt}
          className="h-10 w-10 mb-3 transition-transform duration-300 hover:scale-110"
        />
        <span className="text-center">{card.text}</span>
      </div>
    )}
  </div>
))}
      </div>

      {/* Styles for borders, shadow, and animation */}
      <style jsx>{`
        .border-decor {
          position: absolute;
          width: 40px;
          height: 40px;
          border: 3px solid #e46222;
        }
        .top-left {
          top: -10px;
          left: -10px;
          border-right: none;
          border-bottom: none;
        }
        .bottom-right {
          bottom: -10px;
          right: -10px;
          border-left: none;
          border-top: none;
        }
        .shadow-light:hover {
          box-shadow: 0px 10px 20px rgba(255, 165, 0, 0.4); /* Light orange shadow only on hover */
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </section>
  );
};

export default Contact;
