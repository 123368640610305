import React from "react";
import backgroundImage from "../assets/whyChooseUs.jpg";

const WhyChooseUs = () => {

  const isMobile = window.innerWidth <= 640;

  return (
    <section
      className="relative flex items-center justify-end bg-cover bg-center w-full min-h-screen"
      style={{
        backgroundImage: isMobile
          ? `linear-gradient(to left, rgb(88, 16, 16) 40%, rgba(88, 16, 16, 0) 120%), url(${backgroundImage})`
          : `linear-gradient(to left, rgb(88, 16, 16) 10%, rgba(88, 16, 16, 0) 30%), url(${backgroundImage})`,
      }}
    >
      <div className=" mx-auto px-4 sm:px-8 grid grid-cols-1 lg:grid-cols-2 lg:grid-flow-col-dense gap-4 lg:gap-8 mt-2">
        {/* Empty div to fill left side for larger screens */}
        <div className="hidden lg:block"></div>

        {/* Right Side: Text Section */}
        <div className="text-left text-orange-100 max-w-[100%] sm:max-w-[75%] lg:ml-auto">
          <h2 className="text-3xl md:text-5xl font-bold mb-6">
            Pse Të Na Zgjidhni?
          </h2>
          <p className="text-left text-xl mb-6">
            Strategjitë tona të personalizuara sigurojnë që nevojat dhe qëllimet
            tuaja specifike të përmbushen. Ne qëndrojmë përpara trendeve të
            industrisë dhe përdorim mjetet dhe teknikat më të fundit për të
            ofruar zgjidhje inovative që magjepsin audiencat. Ne e duam atë që
            bëjmë! Pasioni ynë për kreativitetin ndihmon punën tonë, duke
            siguruar që të prodhojmë përmbajtje tërheqëse dhe të paharrueshme
            për markën tuaj.
          </p>
          <h2 className="text-3xl md:text-5xl font-bold mb-6">
            Why choose us?
          </h2>
          <p className="text-left text-xl mb-6">
            We understand that every brand is unique. Our personalized strategies
            ensure that your specific needs and goals are met. We stay ahead of
            industry trends and use the latest tools and techniques to deliver
            innovative solutions that captivate audiences. We love what we do!
            Our passion for creativity fuels our work, ensuring we produce
            engaging and memorable content for your brand.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
