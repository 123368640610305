import React from "react";
import backgroundImage from "../assets/hero.jpg"; // Adjust path based on your setup

const Hero = () => {
  return (
    <section
    className="relative flex items-center min-h-[100vh] bg-cover bg-center"
    style={{
      backgroundImage: `linear-gradient(to right, rgba(88, 16, 16, 0.8) 20%, rgba(88, 16, 16, 0.5) 30%), url(${backgroundImage})`,
    }}
  >
      {/* Text Content */}
      <div className=" px-4 md:px-8 relative z-10">
        <div className="text-left text-orange-100">
          <div className="border-l-50 border-[#b44b16] pl-4 mb-8">
            <div className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 leading-normal">
              <p>"Empowering</p>
              <p className="ml-4 sm:ml-5 md:ml-6 lg:ml-8">Brands With a New</p>
              <p className="ml-4 sm:ml-5 md:ml-6 lg:ml-8">Angle In The Digital</p>
              <p className="ml-4 sm:ml-5 md:ml-6 lg:ml-8">World."</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
