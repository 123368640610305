import React, { useState, useEffect } from "react";
import logoKatror from "../assets/icons/logo katror.svg";

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const handleReadMoreToggle = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    // Function to update the state based on window size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Set the initial state and add the resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="about-us"
      className="relative flex items-center justify-center min-h-screen bg-[#581010] py-20 px-4 sm:px-10 w-full"
    >
      <div className="mx-auto px-0 lg:px-4 grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
        {/* Left Side: Text Section */}
        <div className="text-left text-orange-100 max-w-full sm:max-w-[100%]">
          <h2 className="text-3xl sm:text-5xl font-bold mb-8">Rreth Nesh - Këndi</h2>
          <p className="text-xl mb-8">
            Mirë se vini në Këndi, vendi ku kreativiteti dhe dizajni bashkohen
            për të sjellë ide të paharrueshme në jetë. Ne jemi një ekip
            pasionant profesionistësh të dizajnit grafik, të përkushtuar për t’i
            dhënë formë vizioneve tuaja dhe për t’i sjellë ato në realitet.
          </p>
          <p className="text-xl mb-8">
            Në Këndi, besojmë se çdo projekt është një mundësi për të treguar
            një histori. Qëllimi ynë është të krijojmë zgjidhje vizuale që jo
            vetëm kapin sy të ndjekësve, por edhe transmetojnë mesazhin e duhur
            për audiencën tuaj. Ne ofrojmë një gamë të gjerë shërbimesh, duke
            përfshirë dizajn logo, krijimin e materialeve promocionale,
            packaging, dhe shumë më tepër.
          </p>
          {(showMore || !isSmallScreen) && (
            <>
              <p className="text-xl mb-8">
                Çdo klient është unik dhe ne e trajtojmë çdo projekt me kujdes
                dhe dedikim. Duke dëgjuar nevojat dhe dëshirat tuaja, ne punojmë
                ngushtë me ju për të siguruar që çdo detaj të përputhet me
                vizionin tuaj. Këndi është vendi ku ideja juaj merr jetë dhe çdo
                projekt shndërrohet në një vepër arti.
              </p>
              <p className="text-xl mb-8">
                Le të bashkëpunojmë për të krijuar diçka të jashtëzakonshme së
                bashku!
              </p>
            </>
          )}
          
          {/* "Read More" button visible only on small screens */}
          {isSmallScreen && !showMore && (
            <button
              onClick={handleReadMoreToggle}
              className="text-orange-100 text-xl underline mb-4"
              // className="border-2 border-orange-500 text-orange-100 py-2 px-4 rounded-lg mb-4 bg-[#581010] hover:bg-red-700 transition-colors duration-300 text-xl"

            >
              Lexo më shumë
              {/* {showMore ? "Lexo më pak" : "Lexo më shumë"} */}

            </button>
          )}
          {/* {isSmallScreen && showMore &&(
                <button
                  onClick={handleReadMoreToggle}
                  // className="text-orange-100 text-xl underline mb-4"
                  className="border-2 border-orange-500 text-orange-100 py-2 px-4 rounded-lg mb-4 bg-[#581010] hover:bg-red-700 transition-colors duration-300 text-xl"

                >
                  <div>
                  Lexo më pak

                  </div>
                </button>
              )} */}
        </div>

        <div className="lg:flex justify-center items-center relative">
          {/* Top-right SVG */}
          <img
            src={logoKatror}
            alt="Top-right logo"
            height="550px"
            width="550px"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
