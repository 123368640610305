import React from "react";
import patternImage from "../assets/pattern_image.jpg";
import marketingIcon from "../assets/icons/marketing digjital icon.svg";
import socialMediaIcon from "../assets/icons/menaxhimi rrjeteve icon.svg";
import brandingIcon from "../assets/icons/branding icon.svg";

const Services = () => {
  return (
    <section
      className="relative min-h-screen bg-cover"
      style={{
        backgroundImage: `url(${patternImage})`,
      }}
    >
      <div className="mx-auto px-4 h-full grid gap-12 md:gap-4">
        {/* First Section (Top-Left) */}
        <div className="sm:flex items-start space-x-0 sm:space-x-8 mt-20">
          {/* Image container with border */}
          <div
            className="border-l-[40px] border-[#ea6d2e] sm:h-40 md:h-48 pl-4"
            style={{ borderLeftColor: "rgba(236, 100, 32, 0.7)" }}
          >
            <img
              src={marketingIcon}
              alt="Marketing Icon"
              className="h-40 w-40"
            />
          </div>

          {/* Text content next to the icon */}
          <div className="text-left sm:max-w-[35%] mt-7">
            <h3 className="text-3xl sm:text-4xl font-bold mb-2 text-[#3a0707e3]">
              "Strategji Për Marketing"
            </h3>
            <p className="text-xl text-[#3a0707e3]">
              Ne ju ofrojmë marketing kreativ për të angazhuar audiencën tuaj
              dhe për të diferencuar markën tuaj në treg.
            </p>
          </div>
        </div>

        {/* Second Section (Center-Right) */}
        <div className="flex justify-end items-center w-full mb-6">
          {/* Text and Icons container */}
          <div className="block sm:hidden mb-4">
            <div className="flex flex-col-reverse sm:items-center justify-end space-x-6">
              <div className="text-left sm:max-w-[40%]">
                <h3 className="text-3xl font-bold mb-2 text-[#3a0707e3]">
                  "Menaxhim Të Rrjeteve Sociale"
                </h3>
                <p className="text-xl text-[#3a0707e3]">
                  Ne ju ofrojmë zgjidhje kreative për të përmbushur nevojat
                  tuaja dhe për të rritur suksesin e markës suaj.
                </p>
              </div>

              {/* Icons section */}
              <div
                className="border-r-[40px] border-[#ea6d2e] sm:h-40 md:h-48 flex items-center justify-center"
                style={{ borderRightColor: "rgba(236, 100, 32, 0.7)" }}
              >
                <img
                  src={socialMediaIcon}
                  alt="Social Media Icon"
                  className="h-44 w-44"
                />
              </div>
            </div>
          </div>
        </div>
        {/* larger screen */}
        <div className="hidden sm:flex sm:items-center justify-end space-x-6">
          <div className="text-left sm:max-w-[35%]">
            <h3 className="text-4xl font-bold mb-2 text-[#3a0707e3]">
              "Menaxhim Të Rrjeteve Sociale"
            </h3>
            <p className="text-xl text-[#3a0707e3]">
              Ne ju ofrojmë zgjidhje kreative për të përmbushur nevojat tuaja
              dhe për të rritur suksesin e markës suaj.
            </p>
          </div>

          {/* Icons section */}
          <div
            className="border-r-[40px] border-[#ea6d2e] sm:h-40 md:h-48 flex items-center justify-center"
            style={{ borderRightColor: "rgba(236, 100, 32, 0.7)" }}
          >
            <img
              src={socialMediaIcon}
              alt="Social Media Icon"
              className="h-44 w-44"
            />
          </div>
        </div>

        {/* Third Section (Bottom-Right) */}
        <div className="sm:flex items-start space-x-0 sm:space-x-4 mb-8">
          {/* Image container with border */}
          <div
            className="border-l-[40px] border-[#ea6d2e] sm:h-40 md:h-48 pl-4"
            style={{ borderLeftColor: "rgba(236, 100, 32, 0.7)" }}
          >
            <img
              src={brandingIcon}
              alt="Branding Icon"
              className="h-40 w-40 pr-6"
            />
          </div>
          {/* Text container with max-width applied */}
          <div className="text-left sm:max-w-[40%] mt-7">
            <h3 className="text-3xl sm:text-4xl font-bold mb-2 text-[#3a0707e3]">
              "Dizajn Për Brendin Tuaj"
            </h3>
            <p className="text-xl text-[#3a0707e3]">
              Branding kreativ është procesi i krijimit të një identiteti unik
              dhe tërheqës për një markë, duke përdorur ide inovative dhe
              strategji të veçanta për të diferencuar produktin ose shërbimin në
              treg.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
