import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import AboutUs from "./components/AboutUs";
import Hero from "./components/Hero";
import WhyChooseUs from "./components/WhyChooseUs";
import Footer from "./components/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import Services from "./components/Services";
import Contact from "./components/Contact";

function App() {
  const location = useLocation();
  const shouldShowFooter = location.pathname !== "/contact";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  
  return (
    <>
      <Navbar/>
      <div className="w-full">
        <Routes>
          {/* Show all components for the root ("/") path */}
          <Route
            path="/"
            element={
              <>
                <Hero />
                <AboutUs />
                <WhyChooseUs />
                <Services />
              </>
            }
          />
          {/* Show only the specific component for the other routes */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/why-us" element={<WhyChooseUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />

          {/* <Route path="/contact" element={<Footer />} /> */}
        </Routes>
      </div>
      { shouldShowFooter && <Footer /> }
    </>
  );
}

export default App;
