import React, { useState, useEffect } from "react";
import { Link, useMatch, useResolvedPath, useNavigate } from "react-router-dom";
import logo from "./../assets/icons/logo.svg";
import { IoMenuOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.body.style.overflowX = "hidden";
  });

  return (
    <nav className="bg-[#f4e6d8] px-4 py-1 shadow-md fixed top-0 w-full z-50 h-16">
      <div className="flex justify-between items-center">
        {/* Logo */}
        <button onClick={handleLogoClick} className="flex items-center ml-4">
          <img src={logo} alt="Këndi Logo" className="h-14" />
        </button>

        {/* Hamburger Menu Icon (Visible on small screens only) */}
        <div className="sm:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 text-3xl"
          >
            {isOpen ? (
              <IoMdClose className="text-red-900 hover:text-red-700" />
            ) : (
              <IoMenuOutline className="text-red-900 hover:text-red-700" />
            )}
          </button>
        </div>

        {/* Overlay Menu for small screens */}
        {isOpen && (
          <div
            className="fixed inset-0 p-4 flex flex-col py-1"
            style={{
              backgroundColor: "rgba(244, 230, 216, 0.8)", // Slightly less transparent
              backdropFilter: "blur(40px)",
            }}
          >
            {/* Logo */}
            <button
              onClick={handleLogoClick}
              className="flex items-center ml-4"
            >
              <img src={logo} alt="Këndi Logo" className="h-14" />
            </button>

            {/* Close icon at the top right */}
            <div className="absolute top-4 right-4">
              <button onClick={toggleMenu}>
                <IoMdClose
                  style={{ width: "30px", height: "30px" }}
                  className="text-red-900 hover:text-red-700"
                />
              </button>
            </div>

            {/* Navigation Links */}
            <div className="sm:hidden flex flex-col items-start mt-8 gap-6 overflow-hidden">
              <ul className="list-none space-y-4">
                <CustomLink
                  to="/about"
                  onClick={handleLinkClick}
                  className="text-red-900 text-2xl font-medium hover:text-red-700"
                >
                  About Us
                </CustomLink>
                <CustomLink
                  to="/services"
                  onClick={handleLinkClick}
                  className="text-red-900 text-2xl font-medium hover:text-red-700"
                >
                  Services
                </CustomLink>
                <CustomLink
                  to="/contact"
                  onClick={handleLinkClick}
                  className="text-red-900 text-2xl font-medium hover:text-red-700"
                >
                  Contact
                </CustomLink>
              </ul>
            </div>
          </div>
        )}

        {/* Links Section for larger screens */}
        <div className="hidden sm:flex md:space-x-8 items-center">
          <ul className="flex space-x-4 mr-4">
            <CustomLink
              to="/about"
              className="text-[#e46222] text-xl hover:text-orange-800"
            >
              About Us
            </CustomLink>
            <CustomLink
              to="/services"
              className="text-[#e46222] text-xl hover:text-orange-800"
            >
              Services
            </CustomLink>
            <CustomLink
              to="/contact"
              className="text-[#e46222] text-xl hover:text-orange-800"
            >
              Contact
            </CustomLink>
          </ul>
        </div>
      </div>
    </nav>
  );
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default Navbar;
